﻿
function anchorLinks() {
    for (let anchorLink of document.querySelectorAll('a')) {
        if (!anchorLink.hasAttribute('href')) {
            continue;
        }
        let anchorUrl = new URL(anchorLink.href);
        if (anchorUrl.host !== window.location.host || anchorUrl.pathname !== window.location.pathname) {
            continue;
        }
        if (anchorUrl.hash === "") {
            continue;
        }
        let targetId = anchorUrl.hash.substring(1);
        let target = document.getElementById(targetId);
        if (target !== null) {
            anchorLink.addEventListener('click', function(event) {
                target.scrollIntoView({ behavior: 'smooth' });
                window.history.replaceState(null, '', anchorUrl);
                event.preventDefault();
            });
        }
        else if (targetId === 'top') {
            anchorLink.addEventListener('click', function(event) {
                anchorUrl.hash = '';
                window.scrollTo({ top: 0, behavior: 'smooth' });
                window.history.replaceState(null, '', anchorUrl);
                event.preventDefault();
            });
        }
    }
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', anchorLinks);
}
else {
    anchorLinks();
}
