﻿
function homeLoanCards() {
    for (let homeLoanCardsWidget of document.querySelectorAll('.home-loan-cards')) {
        for (let homeLoan of homeLoanCardsWidget.querySelectorAll('.home-loan-card')) {
            let button = homeLoan.querySelector('button');
            let isExpanded = false;
            function expand() {
                button.setAttribute('aria-expanded', 'true');
                homeLoan.classList.remove('collapsed');
                homeLoan.classList.add('expanded');
                isExpanded = true;
            }
            function collapse() {
                button.setAttribute('aria-expanded', 'false');
                homeLoan.classList.remove('expanded');
                homeLoan.classList.add('collapsed');
                isExpanded = false;
            }
            button.addEventListener('click', function() {
                if (isExpanded) {
                    collapse();
                }
                else {
                    expand();
                }
            });
        }
    }
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', homeLoanCards);
}
else {
    homeLoanCards();
}
