﻿
import loadService from "../vendors/Recite Me/reciteme.js";

/* 
 * Languages list was extracted by opening the Recite Me tool on their official website and running the following:
 * ```
 * JSON.stringify(Array.prototype.map.call(document.getElementById('recite-languages-dropdown').querySelectorAll('.recite-language-set'), function(languageSet) {
 *     let englishName = languageSet.querySelector('.recite_language_main_name_str').textContent;
 *	   let nativeName = languageSet.querySelector('.recite_language_native_name_str').textContent;
 *	   let languageCode = languageSet.getAttribute('data-language');
 *     return {
 *         nativeName: nativeName,
 *         englishName: englishName,
 *         languageCode: languageCode
 *     };
 * }), null, 4)
 */
import languages from "../vendors/Recite Me/languages.json";

function reciteme() {
    let accessibilityToolsButton = document.getElementById('accessibility-tools');
    let languageSelect = document.getElementById('language-select');
    let reciteMeIsLoaded = false;
    window._reciteLoaded = function() {
        reciteMeIsLoaded = true;
    };
    function skipSvgs() {
        for (let svg of document.querySelectorAll('svg')) {
            svg.setAttribute('data-recite-skip', 'true');
        } 
    }
    accessibilityToolsButton.addEventListener('click', function() {
        skipSvgs();
        loadService(function() {
            reciteMeIsLoaded = true;
        });
    });
    let languageSelectButton = languageSelect.querySelector('button');
    let defaultLanguageCode = document.body.getAttribute('lang');
    let languageDropdown;
    function createDropdown() {
        languageDropdown = document.createElement('ul');
        languageDropdown.classList.add('drop-down');
        for (let { nativeName, englishName, languageCode } of languages) {
            if (languageCode === 'default') {
                languageCode = defaultLanguageCode;
            }
            let languageItem = document.createElement('li');
            let languageButton = document.createElement('button');
            languageButton.textContent = `${englishName} (${nativeName})`;
            languageButton.addEventListener('click', function() {
                function translate() {
                    localStorage.setItem('user_lang', languageCode);
                    Recite.Preferences.set('language', languageCode);
                    Recite.Language.Translate.translate(document.body, languageCode);
                }
                if (reciteMeIsLoaded) {
                    translate();
                }
                else {
                    skipSvgs();
                    loadService(function() {
                        translate();
                        reciteMeIsLoaded = true;
                    });
                }
                closeDropdown();
            });
            languageItem.appendChild(languageButton);
            languageDropdown.appendChild(languageItem);
        }
    }
    let isOpen;
    function openDropdown() {
        languageSelect.classList.add('expanded');
        languageSelectButton.setAttribute('aria-expanded', 'true');
        languageDropdown.querySelector('button').focus();
        languageSelect.appendChild(languageDropdown);
        isOpen = true;
    }
    function closeDropdown() {
        languageSelect.classList.remove('expanded');
        languageSelectButton.setAttribute('aria-expanded', 'false');
        languageSelect.removeChild(languageDropdown);
        isOpen = false;
    }
    function toggleDropdown() {
        if (isOpen) {
            closeDropdown();
        }
        else {
            openDropdown();
        }
    }
    languageSelectButton.addEventListener('click', createDropdown, { once: true });
    languageSelectButton.addEventListener('click', toggleDropdown);
    languageSelect.addEventListener('focusout', function(event) {
        if (!languageSelect.contains(event.relatedTarget)) {
            closeDropdown();
        }
    });
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', reciteme);
}
else {
    reciteme();
}
