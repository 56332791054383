﻿
function seminarRegistrationForm() {
    let seminarMetadataElement = document.getElementById('seminar-metadata');
    if (seminarMetadataElement === null) {
        return;
    }
    let seminarMetadata = JSON.parse(seminarMetadataElement.textContent);
    document.querySelector('input[name$="Seminar.Value"]').value = seminarMetadata.Seminar;
    document.querySelector('input[name$="DocumentGUID.Value"]').value = seminarMetadata.DocumentGUID;
    document.querySelector('input[name$="SeminarItemID.Value"]').value = seminarMetadata.SeminarItemID;
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', seminarRegistrationForm);
}
else {
    seminarRegistrationForm();
}
