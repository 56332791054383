﻿
function galleries() {
    for (let gallery of document.querySelectorAll('.gallery')) {
        let showAll = gallery.querySelector('.show-all');
        let galleryItems = gallery.querySelector('.gallery-list').children;
        showAll.addEventListener('click', function() {
            showAll.setAttribute('hidden', 'hidden');
            showAll.setAttribute('aria-hidden', 'true');
            for (let galleryItem of galleryItems) {
                galleryItem.removeAttribute('hidden');
                galleryItem.setAttribute('aria-hidden', 'false');
            }
        });
    }
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', galleries);
}
else {
    galleries();
}
