﻿import initCalculatorHandleDepositRequest from './calculator-handle-deposit-request';
import initCalculatorHandleBorrowRequest from './calculator-handle-borrow-request';
import initCalculatorHandleRepaymentsRequest from './calculator-handle-repayments-request';
import initCalculatorCtaButtons from './calculator-cta-buttons';
import initCalculatorInputMask from './calculator-input-mask';


export default function initCalculators(window) {
    // Global settings under calculator object on window
    window.calculator = {
        loanTypes: {
            25: "Standard",
            47: "Graduate",
            54: "LowDeposit"
        },
    };

    //initCalculatorSuburbSuggestor();
    initCalculatorHandleDepositRequest();
    initCalculatorHandleBorrowRequest();
    initCalculatorHandleRepaymentsRequest();
    initCalculatorInputMask();
    initCalculatorCtaButtons(window);

}