﻿
function logos() {
    for (let logos of document.querySelectorAll('.logos')) {
        let previousButton = logos.querySelector('.previous');
        let nextButton = logos.querySelector('.next');
        let carousel = logos.querySelector('.logos-list');
        if (previousButton === null || nextButton === null || carousel === null) {
            continue;
        }
        previousButton.addEventListener('click', function() {
            carousel.scrollTo({ left: carousel.scrollLeft - 200, behavior: 'smooth' });
        });
        nextButton.addEventListener('click', function() {
            carousel.scrollTo({ left: carousel.scrollLeft + 200, behavior: 'smooth' });
        });
    }
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', logos);
}
else {
    logos();
}
