﻿
function homeLoans() {
    let collapseCurrentlyExpanded = null;
    for (let homeLoanWidget of document.querySelectorAll('.home-loans')) {
        for (let homeLoan of homeLoanWidget.querySelectorAll('.home-loan')) {
            let button = homeLoan.querySelector('button');
            let isExpanded = false;
            function expand() {
                if (collapseCurrentlyExpanded !== null) {
                    collapseCurrentlyExpanded();
                }
                button.setAttribute('aria-expanded', 'true');
                homeLoan.classList.remove('collapsed');
                homeLoan.classList.add('expanded');
                isExpanded = true;
                collapseCurrentlyExpanded = collapse;
            }
            function collapse() {
                button.setAttribute('aria-expanded', 'false');
                homeLoan.classList.remove('expanded');
                homeLoan.classList.add('collapsed');
                isExpanded = false;
                collapseCurrentlyExpanded = null;
            }
            button.addEventListener('click', function() {
                if (isExpanded) {
                    collapse();
                }
                else {
                    expand();
                }
            });

            let details = homeLoan.querySelector('.details');
            let detailsExpandButton = details.querySelector('.expand');
            let detailsIsExpanded = false;
            function expandDetails() {
                details.classList.remove('collapsed');
                details.classList.add('expanded');
                detailsExpandButton.setAttribute('aria-expanded', 'true');
                detailsIsExpanded = true;
            }
            function collapseDetails() {
                details.classList.remove('expanded');
                details.classList.add('collapsed');
                detailsExpandButton.setAttribute('aria-expanded', 'false');
                detailsIsExpanded = false;
            }
            detailsExpandButton.addEventListener('click', function() {
                if (detailsIsExpanded) {
                    collapseDetails();
                }
                else {
                    expandDetails();
                }
            });
        }
    }
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', homeLoans);
}
else {
    homeLoans();
}
