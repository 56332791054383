﻿
if (!window.hasOwnProperty('onscrollend')) {
    let timeout = null;
    window.addEventListener('scroll', function() {
        if (timeout !== null) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(function() {
            timeout = null;
            window.dispatchEvent(new Event('scrollend'));
        }, 100);
    });
}