﻿
function header() {
    let menu = document.getElementById('header');
    let toggleMenuButton = document.getElementById('toggle-menu-button');
    let isExpanded = false;
    function expand() {
        toggleMenuButton.setAttribute('aria-expanded', 'true');
        toggleMenuButton.setAttribute('aria-label', "Close menu");
        menu.classList.remove('collapsed');
        menu.classList.add('expanded');
        isExpanded = true;
    }
    function collapse() {
        toggleMenuButton.setAttribute('aria-expanded', 'false');
        toggleMenuButton.setAttribute('aria-label', "Open menu");
        menu.classList.remove('expanded');
        menu.classList.add('collapsed');
        isExpanded = false;
    }
    toggleMenuButton.addEventListener('click', function() {
        if (isExpanded) {
            collapse();
        }
        else {
            expand();
        }
    });
    let buttonBar = menu.querySelector('.btn-bar');
    function stickyOnScroll() {
        let buttonBarBoundingRectangle = buttonBar.getBoundingClientRect();
        if (buttonBarBoundingRectangle.bottom < 0) {
            menu.classList.add('sticky');
        }
        else {
            menu.classList.remove('sticky');
        }
    }
    document.addEventListener('scroll', stickyOnScroll);
    stickyOnScroll();
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', header);
}
else {
    header();
}
