﻿import {
    formatCurrency,
    generateVirtualPageView,
    maybeParseFloat
} from "./calculator-utils";

// CORPORATE

export function updateCalculatorButtonText(pretext, amount, calcType) {
    document.querySelector(`.action-button`).classList.remove('loading');
    document.querySelector(".action-button").classList.remove('calculator-error');
    document.querySelector(`.result-label.${calcType}`).innerHTML = pretext;
    document.querySelector(`.result-amount.${calcType}`).innerHTML = amount;
}

export default function (results) {
    // Update calculator button with the top amount
    window.results.Standard = results;

    document.querySelector('.result-tab-container.deposit').classList.remove('selected-for-print');
    document.querySelector('.result-tab-container.borrow').classList.remove('selected-for-print');
    document.querySelector('.result-tab-container.repayments').classList.remove('selected-for-print');
    document.querySelector('[data-repayments-printfill="LoanAmount"]').innerHTML = `$${document.getElementById("repayments_loan_amount").value}`;

    if (results.status != "Success") {
        document.querySelector('.result-tab-container.repayments').classList.add('selected-for-print');;
    }


    const printContainers = [].slice.call(document.querySelectorAll('.print-container'));

    for (let item of printContainers) {
        item.classList.remove('selected-for-print');
    }

    // make visible on print
    document.getElementById('repayments_print_container').classList.add('selected-for-print');


    let formatted_results = {}
    results.scenarios.forEach(function (item, k) {
        var yearsDecimalString = (item.estimatedTerms / 12).toString();
        var years = Math.floor(item.estimatedTerms / 12);
        var months = item.estimatedTerms % 12;
        //console.log("YEARS:" + years + " | MONTHS:" + months + " | FROM : " + item.estimatedTerms);

        if (years.length == 1) {
            // No months?
            console.log("no months?");
            console.log(years);
        }
        if ((years * 12) + months != item.estimatedTerms) {
            //alert(1);
            console.warn("LOAN TERM DOES NOT ADD UP");
            console.warn((years * 12) + "+" + months + "=" + item.estimatedTerms)
        }
        formatted_results[item.scenarioName.replace('-', '_')] = {
            years: years,
            months: months,
            amount: (item.repaymentsAmount >= 0) ? item.repaymentsAmount : 0,
        }
    });
    //console.log(formatted_results);
    var lowestWeekly = 9999999;
    for (var prop in formatted_results) {
        if (Object.prototype.hasOwnProperty.call(formatted_results, prop)) {
            if (prop.includes("WEEKLY")) {
                formatted_results[prop]
                if (formatted_results[prop].amount < lowestWeekly) {
                    lowestWeekly = formatted_results[prop].amount;
                }
            }
        }
    }
    if (lowestWeekly == 9999999) {
        lowestWeekly = 0;
    }

    updateCalculatorButtonText("Your estimated weekly repayments would be:", formatCurrency(lowestWeekly, true), 'repayments');
    //document.querySelector(".advantage_text").hide();

    document.getElementById('repayments_calculate').classList.remove('loading');

    // Tabs Text
    let borrow = document.getElementById(`repayments_homestart_text`);
    let repayments = document.getElementById(`repayments_graduate_text`);
    let contribution = document.getElementById(`repayments_lowdeposit_text`);

    borrow.innerHTML = formatCurrency(formatted_results.STANDARDWEEKLY.amount, true) + " per week";
    repayments.innerHTML = formatCurrency(formatted_results.GRADUATEWEEKLY.amount, true) + " per week";
    contribution.innerHTML = formatCurrency(formatted_results.LOWDEPOSITWEEKLY.amount, true) + " per week"


    //  Update weekly/fortnightly/monthly in each
    // Standard
    document.getElementById('repayments_homestart_weekly_header').innerHTML = formatCurrency(formatted_results.STANDARDWEEKLY.amount, true);
    document.getElementById('repayments_homestart_weekly_text').innerHTML = formatted_results.STANDARDWEEKLY.years + ' years and ' + formatted_results.STANDARDWEEKLY.months + ' months';

    document.getElementById('repayments_homestart_fortnightly_header').innerHTML = formatCurrency(formatted_results.STANDARDFORTNIGHTLY.amount, true);
    document.getElementById('repayments_homestart_fortnightly_text').innerHTML = formatted_results.STANDARDFORTNIGHTLY.years + ' years and ' + formatted_results.STANDARDFORTNIGHTLY.months + ' months';

    document.getElementById('repayments_homestart_monthly_header').innerHTML = formatCurrency(formatted_results.STANDARDMONTHLY.amount, true);
    document.getElementById('repayments_homestart_monthly_text').innerHTML = formatted_results.STANDARDMONTHLY.years + ' years and ' + formatted_results.STANDARDMONTHLY.months + ' months';

    // Graduate
    document.getElementById('repayments_graduate_monthly_header').innerHTML = formatCurrency(formatted_results.GRADUATEMONTHLY.amount, true);
    document.getElementById('repayments_graduate_monthly_text').innerHTML = formatted_results.GRADUATEMONTHLY.years + ' years and ' + formatted_results.GRADUATEMONTHLY.months + ' months';

    document.getElementById('repayments_graduate_weekly_header').innerHTML = formatCurrency(formatted_results.GRADUATEWEEKLY.amount, true);
    document.getElementById('repayments_graduate_weekly_text').innerHTML = formatted_results.GRADUATEWEEKLY.years + ' years and ' + formatted_results.GRADUATEWEEKLY.months + ' months';

    document.getElementById('repayments_graduate_fortnightly_header').innerHTML = formatCurrency(formatted_results.GRADUATEFORTNIGHTLY.amount, true);
    document.getElementById('repayments_graduate_fortnightly_text').innerHTML = formatted_results.GRADUATEFORTNIGHTLY.years + ' years and ' + formatted_results.GRADUATEFORTNIGHTLY.months + ' months';

    document.getElementById('repayments_graduate_monthly_header').innerHTML = formatCurrency(formatted_results.GRADUATEMONTHLY.amount, true);
    document.getElementById('repayments_graduate_monthly_text').innerHTML = formatted_results.GRADUATEMONTHLY.years + ' years and ' + formatted_results.GRADUATEMONTHLY.months + ' months';


    // LowDeposit
    document.getElementById('repayments_lowdeposit_monthly_header').innerHTML = formatCurrency(formatted_results.LOWDEPOSITMONTHLY.amount, true);
    document.getElementById('repayments_lowdeposit_monthly_text').innerHTML = formatted_results.LOWDEPOSITMONTHLY.years + ' years and ' + formatted_results.LOWDEPOSITMONTHLY.months + ' months';

    document.getElementById('repayments_lowdeposit_weekly_header').innerHTML = formatCurrency(formatted_results.LOWDEPOSITWEEKLY.amount, true);
    document.getElementById('repayments_lowdeposit_weekly_text').innerHTML = formatted_results.LOWDEPOSITWEEKLY.years + ' years and ' + formatted_results.LOWDEPOSITWEEKLY.months + ' months';

    document.getElementById('repayments_lowdeposit_fortnightly_header').innerHTML = formatCurrency(formatted_results.LOWDEPOSITFORTNIGHTLY.amount, true);
    document.getElementById('repayments_lowdeposit_fortnightly_text').innerHTML = formatted_results.LOWDEPOSITFORTNIGHTLY.years + ' years and ' + formatted_results.LOWDEPOSITFORTNIGHTLY.months + ' months';

    document.getElementById('repayments_lowdeposit_monthly_header').innerHTML = formatCurrency(formatted_results.LOWDEPOSITMONTHLY.amount, true);
    document.getElementById('repayments_lowdeposit_monthly_text').innerHTML = formatted_results.LOWDEPOSITMONTHLY.years + ' years and ' + formatted_results.LOWDEPOSITMONTHLY.months + ' months';


    document.getElementById('repayments_results').style.display = 'block'
    generateVirtualPageView();

};
