﻿
function accordions() {
    for (let accordion of document.querySelectorAll('.accordion')) {
        for (let section of accordion.querySelectorAll('.section')) {
            let heading = section.querySelector('button');
            let region = section.querySelector('.region');
            function expand() {
                region.removeAttribute('hidden');
                region.animate(
                    [
                        { maxHeight: '0', opacity: '0' },
                        { maxHeight: `${region.getBoundingClientRect().height}px`, opacity: '100%' }
                    ],
                    { easing: 'ease', duration: 500 }
                );
                heading.setAttribute('aria-expanded', true);
                heading.classList.add('expanded');
                region.setAttribute('aria-hidden', false);
            }
            function collapse() {
                heading.setAttribute('aria-expanded', false);
                heading.classList.remove('expanded');
                region.setAttribute('aria-hidden', true);
                let animation = region.animate(
                    [
                        { maxHeight: `${region.getBoundingClientRect().height}px` },
                        { maxHeight: '0', opacity: '0' }
                    ],
                    { easing: 'ease', duration: 500 }
                );
                animation.addEventListener('finish', function () {
                    region.setAttribute('hidden', true);
                });
            }
            let isExpanded = false;
            function toggle() {
                if (isExpanded) {
                    collapse();
                    isExpanded = false;
                }
                else {
                    expand();
                    isExpanded = true;
                }
            }
            heading.addEventListener('click', toggle);
        }
    }
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', accordions);
}
else {
    accordions();
}
