﻿
function steps() {
    for (let steps of document.querySelectorAll('.steps')) {
        let checkboxes = steps.querySelector('.steps-list').querySelectorAll('input[type="checkbox"]');
        let eligible = steps.querySelector('.eligible');
        let ineligible = steps.querySelector('.ineligible');
        let continueButton = steps.querySelector('.continue').querySelector('button');
        function isEligible() {
            return Array.prototype.every.call(checkboxes, function(checkbox) {
                return checkbox.checked;
            });
        }
        continueButton.addEventListener('click', function(event) {
            if (isEligible()) {
                for (let element of steps.children) {
                    element.setAttribute('hidden', 'hidden');
                }
                eligible.removeAttribute('hidden');
                eligible.scrollIntoView({ behavior: 'smooth' });
            }
            else {
                ineligible.removeAttribute('hidden');
                eligible.setAttribute('hidden', 'hidden');
            }
        });
    }
}

function initCustomerRegistration() {

    let registrationFormContainer = document.querySelector('.eligible');

    if (registrationFormContainer) {
        let submitButton = registrationFormContainer.querySelector('button[type="submit"]');
        if (submitButton) {
            submitButton.addEventListener('click', function (event) {
                if (this.classList.contains('disable')) {
                    return false;
                }
                this.classList.add('disable');

                const firstName = registrationFormContainer.querySelector('form input[name*="FirstName.Value"]').value;
                const lastName = registrationFormContainer.querySelector('form input[name*="LastName.Value"]').value;
                const mobile = registrationFormContainer.querySelector('form input[name*="Phone.Value"]').value;

                registrationFormContainer.querySelector('form input[name*="AllFields.Value"]').value = JSON.stringify({
                    firstName,
                    lastName,
                    mobile
                });
            });
        }
    }
}


function initListeners() {
    steps();
    initCustomerRegistration();
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initListeners);
}
else {
    initListeners();
}
