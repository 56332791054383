﻿
function activateAccountForm() {
    let activateAccountForm = document.getElementById('activate-account-form');
    if (activateAccountForm === null) {
        return;
    }
    let activateAccountButton = document.getElementById('activate-account-button');
    let emailInput = document.getElementById('email-address');
    let emailValidation = document.getElementById('email-address-validation');
    let customerIdInput = document.getElementById('customer-number');
    let customerIdValidation = document.getElementById('customer-number-validation');
    let recaptchaValidation = document.getElementById('recaptcha-validation');
    let successModal = document.getElementById('success-modal');
    let successConfirm = document.getElementById('success-confirm');
    let errorModal = document.getElementById('error-modal');
    let errorContent = document.getElementById('error-content');
    let defaultErrorContentText = Array.from(errorContent.children);
    let errorConfirm = document.getElementById('error-confirm');
    activateAccountButton.addEventListener('click', async function () {
        if (customerIdInput.value === "") {
            customerIdValidation.textContent = "Please enter your customer number";
            return;
        }
        else if (!customerIdInput.checkValidity()) {
            customerIdValidation.textContent = "Please check your customer number";
            return;
        }
        else {
            customerIdValidation.textContent = "";
        }
        let customerId = customerIdInput.value;

        if (emailInput.value === "") {
            emailValidation.textContent = "Please enter your email address";
            return;
        }
        else if (!emailInput.checkValidity()) {
            emailValidation.textContent = "Please check your email address";
            return;
        }
        else {
            emailValidation.textContent = "";
        }
        let email = emailInput.value;

        if (grecaptcha.getResponse() === '') {
            recaptchaValidation.textContent = "Please complete the reCAPTCHA";
            return;
        }
        else {
            recaptchaValidation.textContent = "";
        }

        let response = await window.fetch('/api/activateaccount/activateuser', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({
                hucid: customerId,
                email: email,
            }),
        });
        if (!response.ok) {
            errorContent.replaceChildren(...defaultErrorContentText);
            errorModal.removeAttribute('hidden');
            return;
        }
        let result = await response.json();
        if (result.status === 'Fail') {
            let errorParagraph = document.createElement('p');
            errorParagraph.classList.add('text', 'atlantic-blue');
            errorParagraph.textContent = result.errors[0].description;
            errorContent.replaceChildren(errorParagraph);
            errorModal.removeAttribute('hidden');
            return;
        }
        
        successModal.removeAttribute('hidden');
    });
    successConfirm.addEventListener('click', function () {
        successModal.setAttribute('hidden', 'hidden');
    });
    errorConfirm.addEventListener('click', function () {
        errorModal.setAttribute('hidden', 'hidden');
    })
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', activateAccountForm);
}
else {
    activateAccountForm();
}
