﻿import initAlertBanner from './alerts/alert-banner';
import initCalculators from './calculators/calculator.js';
import initCalculatorSuburbSuggestor from './calculators/calculator-suburb-suggestor.js';
import initKeyFacts from './Components/key-facts.js';
import { hasPDFViewer } from "./utils/utils.js";

//import initCalculatorHelpers from './calculators/calculator-helpers.js';

export default function main() {
    window.inputMasks = {};
    window.canRenderPDF = hasPDFViewer(window, navigator);

    initAlertBanner();
    initCalculators(window);
    initCalculatorSuburbSuggestor();
    initKeyFacts(window);
    //initCalculatorHelpers(window);
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', main);
}
else { 
    main();
}

import "./CMS/form.js";

import "./Components/activate-account-form.js";
import "./Components/anchor-link.js";
import "./Components/category-filter.js";
import "./Components/footer.js";
import "./Components/header.js";
import "./Components/navigation.js";
import "./Components/page-navigation.js";
import "./Components/reciteme.js";
import "./Components/scrollend-event-polyfill.js";
import "./Components/seminar-registration-form.js";
import "./Components/sitemap.js";
import "./Components/user-menu.js";

import "./Widgets/accordion.js";
import "./Widgets/gallery.js";
import "./Widgets/home-loan-cards.js";
import "./Widgets/home-loans.js";
import "./Widgets/logos.js";
import "./Widgets/steps.js";

