﻿import calculatorDisplayRepaymentsResults from "./calculator-handle-repayments-results";

export function calculatorLoadStart() {
    document.querySelector(".calculator-error").classList.remove('loading');
    document.querySelector('.results-tabs').style.visibility = 'hidden';
    document.querySelector('.call-to-actions').style.visibility = 'hidden';
    document.querySelector('.calculator-results').classList.remove('no-low-repayments');
}

export const maybeParseInt = (value) => {
    if (value === "") {
        return "0"; // Needs to be an string for the api
    }
    value = value.toString().replace(/,/g, ''); // safely remove commas if they are present
    return parseInt(value).toString();
}

export function calculatorLoadError(text = false) {
    if (text != false) {
        //console.log('custom error text', text);
        document.querySelector('.repayments-error > p').innerHTML = text;
    }
    document.querySelector('.repayments-error').style.display = 'block';
    document.getElementById('repayments_calculate').classList.remove('loading');
}

export default function () {
    let repaymentsCalculate = document.getElementById("repayments_calculate");
    let repaymentsReset = document.getElementById("repayments_reset");
    if (repaymentsCalculate) {
        repaymentsCalculate.addEventListener("click", function (e) {
            e.preventDefault();
            document.querySelector('.repayments-error').style.display = 'none';
            document.getElementById('repayments_calculate').classList.add('loading');

            window.results = new Array(); // reset any potential other results

            let loanAmount = maybeParseInt(document.getElementById("repayments_loan_amount").value.toString().replace(',', ''));
            let minLoanAmount = parseFloat(document.getElementById('validation-data').getAttribute('data-loan-min'));
            let maxLoanAmount = parseFloat(document.getElementById('validation-data').getAttribute('data-loan-max'));

            if (parseFloat(loanAmount) >= maxLoanAmount) {
                calculatorLoadError('No result(s) found for the provided parameter(s)');
                return;
            }
            if (parseFloat(loanAmount) <= minLoanAmount) {
                calculatorLoadError(`Please enter a loan amount greater than $${minLoanAmount}.`);
                return;
            }

            let data = {
                "LoanAmount": loanAmount,
            };

            let url = window.location.origin + '/api/calculator/WAMR';
            // console.log("posting to " + url, data);

            fetch(url, {
                method: 'POST', // Use POST if you want to send data in the request body
                headers: {
                    'Content-Type': 'application/json' // Send as JSON
                },
                body: JSON.stringify(data) // Convert data object to JSON string for sending
            })
                .then(response => {
                    if (!response.ok) {
                        document.querySelector('.repayments-error').style.display = 'flex';
                        document.getElementById('repayments_calculate').classList.remove('loading');
                        //calculatorLoadError('Error retrieving the data');
                        //throw new Error('Network response was not ok');
                    } else {
                        response.json().then(result => {
                            calculatorDisplayRepaymentsResults(result);
                            //    console.log(result.scenarios);
                        });
                    }
                })
                .catch(error => {
                    calculatorLoadError(); // Handle the error case
                });

        });
        repaymentsReset.addEventListener("click", function (e) {
            e.preventDefault();
            document.getElementById('repayments_results').style.display = 'none';
            document.getElementById("repayments_loan_amount").value = '';
        });

    }
};
