﻿
function navigation() {
    let navigation = document.querySelector('.navigation');
    if (navigation === null) {
        return;
    }
    let hero = navigation.previousElementSibling;
    document.addEventListener('scroll', function() {
        let heroBoundingRectangle = hero.getBoundingClientRect();
        if (heroBoundingRectangle.bottom < 64 + 70) {
            navigation.classList.add('sticky');
        }
        else {
            navigation.classList.remove('sticky');
        }
    });
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', navigation);
}
else {
    navigation();
}
