﻿
function categoryFilter() {
    let categoryFilter = document.querySelector('.category-filter');
    if (categoryFilter === null) {
        return;
    }
    let toggleButton = categoryFilter.querySelector('.toggle');
    let isExpanded = false;
    function expand() {
        toggleButton.setAttribute('aria-expanded', 'true');
        toggleButton.setAttribute('aria-label', "Close category filter");
        categoryFilter.classList.remove('collapsed');
        categoryFilter.classList.add('expanded');
        isExpanded = true;
    }
    function collapse() {
        toggleButton.setAttribute('aria-expanded', 'false');
        toggleButton.setAttribute('aria-label', "Open category filter");
        categoryFilter.classList.remove('expanded');
        categoryFilter.classList.add('collapsed');
        isExpanded = false;
    }
    toggleButton.addEventListener('click', function() {
        if (isExpanded) {
            collapse();
        }
        else {
            expand();
        }
    });
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', categoryFilter);
}
else {
    categoryFilter();
}
