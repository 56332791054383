﻿
function sitemap() {
    let sitemap = document.querySelector('.sitemap');
    if (sitemap === null) {
        return;
    }
    function showMoreOnClick(sitemap) {
        for (let hasMore of sitemap.querySelectorAll('.has-more')) {
            let showMore = hasMore.querySelector('.show-more');
            let more = hasMore.querySelector('.more');
            showMore.addEventListener('click', function() {
                more = Array.from(more.content.children);
                hasMore.replaceWith(...more);
                more.forEach(showMoreOnClick);
            });
        }
    }
    showMoreOnClick(sitemap);
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', sitemap);
}
else {
    sitemap();
}
