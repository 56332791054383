﻿export default function () {

function dismissBanner(alertID) {
    localStorage.setItem('alert_banner_' + alertID, "yes");
    if (typeof (alertID) === undefined) {
        return;
    }
}

function slideup(element, duration = 500) {
    element.animate(
        [
            { maxHeight: `${element.getBoundingClientRect().height}px`, opacity: '100%' },
            { maxHeight: '0', opacity: '0' }
        ],
        { easing: 'ease', duration: duration }
    );
    window.setTimeout(function () {
        element.setAttribute('hidden', 'hidden');
    }, duration - 10)
}

    [...(document.getElementsByClassName('alert-banner-close'))].forEach(d => {
        
        var alertId = d.getAttribute('data-alert-banner-id');
        //console.log(`Alert Id: ${alertId}`)
        var alertIdentifier = `alert_banner_${alertId}`;
        const alreadyClicked = localStorage.getItem(alertIdentifier);
        //console.log(`Id: ${alertIdentifier}`)
        //console.log(`Clicked: ${alreadyClicked}`)
        if (alreadyClicked !== "yes") {
            var element = d.parentElement.parentElement
            element.removeAttribute('hidden');
            d.addEventListener('click', function (e) {
                e.preventDefault()
                var element = d.parentElement.parentElement
                if (element) {
                    slideup(element);
                }
                var alertID = this.getAttribute('data-alert-banner-id');
                dismissBanner(alertID);
            });
        }
    })
}
