﻿
function footer() {
    for (let footerMenu of document.querySelectorAll('.footer-menu')) {
        let menu = footerMenu.querySelector('.content');
        let toggleMenuButton = footerMenu.querySelector('.toggle');
        let isExpanded = footerMenu.classList.contains('expanded');
        function expand() {
            toggleMenuButton.setAttribute('aria-expanded', 'true');
            footerMenu.classList.remove('collapsed');
            footerMenu.classList.add('expanded');
            isExpanded = true;
        }
        function collapse() {
            toggleMenuButton.setAttribute('aria-expanded', 'false');
            footerMenu.classList.remove('expanded');
            footerMenu.classList.add('collapsed');
            isExpanded = false;
        }
        toggleMenuButton.addEventListener('click', function () {
            if (isExpanded) {
                collapse();
            }
            else {
                expand();
            }
        });
    }
}


function toggleNewsletterSubscribeForm() {
    const masklist = document.querySelectorAll(".mask");
    for (const m of masklist) {
        if (m.style.visibility == 'hidden') {
            m.style.visibility = 'visible';
        } else {
            m.style.visibility = 'hidden'
        }
    }
    const letterlist = document.querySelectorAll(".newsletter-subscription");
    for (const nl of letterlist) {
        if (nl.style.visibility == 'hidden') {
            nl.style.visibility = 'visible';
        } else {
            nl.style.visibility = 'hidden'
        }
    }
}

function footerNewsletterForm() {
    let subscribeButton1 = document.getElementById('footerSubscribeNewsletter1');
    if (subscribeButton1 !== null) {
        subscribeButton1.addEventListener('click', function () {
            toggleNewsletterSubscribeForm();
        });
    }
    let subscribeButton2 = document.getElementById('footerSubscribeNewsletter2');
    if (subscribeButton2 !== null) {
        subscribeButton2.addEventListener('click', function () {
            toggleNewsletterSubscribeForm();
        });
    }
    let subscribeCancelButtons = document.querySelectorAll('.form-modal-cancel');
    for (const btn of subscribeCancelButtons) {
        btn.addEventListener('click', function () {
            const masklist = document.querySelectorAll(".mask");
            for (const m of masklist) {
                if (m.style.visibility != 'hidden') {
                    m.style.visibility = 'hidden'
                }
            }
            const letterlist = document.querySelectorAll(".newsletter-subscription");
            for (const nl of letterlist) {
                if (nl.style.visibility != 'hidden') {
                    nl.style.visibility = 'hidden'
                }
            }
        });
    }
}


function runSripts()
{
    footer();
    footerNewsletterForm();
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', runSripts);
}
else {
    runSripts();
}
