﻿import IMask from '../vendors/iMask/imask.js';

export default function () {
    let inputIdsToMask = [
        "borrow_borrower_1_income",
        "borrow_borrower_2_income",
        "borrow_borrower_3_income",
        "borrow_borrower_4_income",
        //"borrow_borrower_5_income",
        "borrow_total_credit_limit",
        "borrow_other_commitments",
        "borrow_property_price",
        "deposit_property_price",
        "repayments_loan_amount",
    ];

    const maskElement = (el, id) => {
        var result = IMask(el, {
            mask: 'num',
            blocks: {
                num: {
                    mask: Number,
                    thousandsSeparator: ','
                }
            }
        });


        window.inputMasks[id] = result;

        el.classList.add('masked');
    }

    inputIdsToMask.forEach(elemId => {
        var elementToMask = document.getElementById(elemId);
        //console.log(`Element to mask ${elementToMask}`)
        if (elementToMask) {
            maskElement(elementToMask, elemId );
        }
    });



}
