﻿
function pageNavigation() {
    let pageNavigation = document.querySelector('.page-navigation');
    if (pageNavigation === null) {
        return;
    }
    let toggleButton = pageNavigation.querySelector('.toggle');
    let isExpanded = false;
    function expand() {
        window.removeEventListener('scrollend', collapse);
        toggleButton.setAttribute('aria-expanded', 'true');
        toggleButton.setAttribute('aria-label', "Close page navigation");
        pageNavigation.classList.remove('collapsed');
        pageNavigation.classList.add('expanded');
        isExpanded = true;
    }
    function collapse() {
        toggleButton.setAttribute('aria-expanded', 'false');
        toggleButton.setAttribute('aria-label', "Open page navigation");
        pageNavigation.classList.remove('expanded');
        pageNavigation.classList.add('collapsed');
        isExpanded = false;
    }
    toggleButton.addEventListener('click', function() {
        if (isExpanded) {
            collapse();
        }
        else {
            expand();
        }
    });
    for (let navigationLink of pageNavigation.querySelectorAll('.page-navigation-item a')) {
        navigationLink.addEventListener('click', function() {
            window.addEventListener('scrollend', collapse);
        });
    }
    document.addEventListener('scroll', function() {
        let pageNavigationBoundingRectangle = pageNavigation.getBoundingClientRect();
        if (pageNavigationBoundingRectangle.top === 92) {
            pageNavigation.classList.add('sticky');
        }
        else {
            pageNavigation.classList.remove('sticky');
        }
    });
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', pageNavigation);
}
else {
    pageNavigation();
}
