﻿////import {
////    maybeParseInt,
////} from "./calculator-utils";
import calculatorDisplayDepositResults from "./calculator-handle-deposit-results";
import { getPostCode } from "./calculator-suburb-list";


export function calculatorLoadStart() {
    document.querySelector(".calculator-error").classList.remove('loading');
    document.querySelector('.results-tabs').style.visibility = 'hidden';
    document.querySelector('.call-to-actions').style.visibility = 'hidden';
    document.querySelector('.calculator-results').classList.remove('no-low-deposit');
    document.querySelector('.secondary_product a').setAttribute('href', '/home-loans/borrowing-boost-loans/shared-equity-option'); //  HERE
    document.querySelector('.secondary_product a').innerHTML = 'Shared Equity Option*'; /*  HERE*/
}

export const maybeParseInt = (value) => {
    if (value === "") {
        return "0"; // Needs to be an string for the api
    }
    value = value.toString().replace(/,/g, ''); // safely remove commas if they are present
    return parseInt(value).toString();
}

export function calculatorLoadError(text = false) {
    if (text != false) {
        //console.log('custom error text', text);
        document.querySelector('.deposit-error > p').innerHTML = text;
    }
    document.querySelector('.deposit-error').style.display = 'block';
    document.getElementById('deposit_calculate').classList.remove('loading');
}

export function validateForm() {
    document.getElementById("deposit_property_price").value;
    let givenPurchasePrice = maybeParseInt(document.getElementById("deposit_property_price").value).toString();

    let minPurchasePrice = parseFloat(document.getElementById('validation-data').getAttribute('data-property-min'));
    let maxPurchasePrice = parseFloat(document.getElementById('validation-data').getAttribute('data-property-max'));

    if (parseFloat(givenPurchasePrice) >= maxPurchasePrice) {
        calculatorLoadError('No result(s) found for the provided parameter(s)');
        return false;
    }

    if (parseFloat(givenPurchasePrice) <= minPurchasePrice) {
        calculatorLoadError(`Please enter a property purchase price greater than $${minPurchasePrice}, without symbols and spaces.`);
        return false;
    }

    return true;
}

export default function () {
    let depositCalculate = document.getElementById("deposit_calculate");
    let depositReset = document.getElementById("deposit_reset");
    if (depositCalculate) {
        depositCalculate.addEventListener("click", function (e) {
            e.preventDefault();
            document.querySelector('.deposit-error').style.display = 'none';
            document.getElementById('deposit_calculate').classList.add('loading');
            var isValid = validateForm();

            if (isValid == false) {
                return;
            } else {

                window.results = new Array(); // reset any potential other results
                let suburb = (document.getElementById('deposit_suburb').value != "") ? document.getElementById('deposit_suburb').value : "ADELAIDE";
                document.getElementById('deposit_suburb').value = suburb

                let data = {
                    "GivenPurchasePrice": maybeParseInt(document.getElementById("deposit_property_price").value).toString(),
                    "IsFirstHomeBuyer": document.getElementById("deposit_first_home_buyer_yes").checked ? "true" : "false",
                    "Postcode": getPostCode(suburb),
                    "Purpose": document.getElementById("deposit_build_or_buy_build").checked ? "BuyLandAndBuild" : "BuyHome",
                };

                let url = window.location.origin + '/api/calculator/hmdin';
                //console.log("posting to " + url, data);

                fetch(url, {
                    method: 'POST', // Use POST if you want to send data in the request body
                    headers: {
                        'Content-Type': 'application/json' // Send as JSON
                    },
                    body: JSON.stringify(data) // Convert data object to JSON string for sending
                })
                    .then(response => {
                        if (!response.ok) {
                            //throw new Error('Network response was not ok');
                            document.getElementById('deposit_calculate').classList.remove('loading');
                            document.querySelector('.deposit-error').style.display = 'flex';
                        } else {
                            response.json().then(result => {
                                calculatorDisplayDepositResults(result, 'deposit')
                                //    console.log(result.scenarios);
                            });
                        }
                    })
                    //.catch(error => {
                    //    calculatorLoadError(); // Handle the error case
                    //});

            }
        });
        depositReset.addEventListener("click", function (e) {
            e.preventDefault();
            document.getElementById('deposit_results').style.display = 'none';
            document.getElementById('deposit_property_price').value = '';
            document.getElementById('deposit_build_or_buy_buy').checked = true;
            document.getElementById('deposit_first_home_buyer_yes').checked = true;
            document.getElementById('deposit_suburb').value = '';
        });

    }
};
