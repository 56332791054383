﻿export default function (window) {
    // Don't run on calculator page
    //if ($('.calculator-page').length <= 0) {
    //    return;
    //}

    let currentlyActiveCalculator = "";
    const printContainers = [].slice.call(document.querySelectorAll('.print-container'));
    let printContainer = null;

    /**
     * Changes the print visibility to the active tab
     */
    function updatePrintContainers(isPrintClick = false) {
        // get active tab
        currentlyActiveCalculator = document.querySelector('.tab-header.is-active').getAttribute('data-type');
        currentlyActiveCalculator = currentlyActiveCalculator != null ? currentlyActiveCalculator.split('#')[1] : ''; //->deposit

        // disable all print containers
        for (let item of printContainers) {
            item.classList.remove('selected-for-print');
        }

        // make visible on print
        if (isPrintClick && printContainer !== null) {
            printContainer.classList.add('selected-for-print');
        }
    }

    /** CTA Print button
      | Generates Virtual Page View
      | Opens print window
      */
    var numericUpdownButtons = [].slice.call(document.querySelectorAll('button.input-plus-minus'))

    numericUpdownButtons.forEach((button) => {
        button.addEventListener('click', function (event) {
            event.preventDefault();
            var inputId = '';
            var increment = true;

            if (this.classList.contains('svg-minus')) {
                inputId = this.getAttribute("for")
                increment = false;
            } else if (this.classList.contains('svg-plus')) {
                inputId = this.getAttribute("for")
                increment = true;
            }
            if (inputId !== '') {
                updateUpDownInput(inputId, increment)
            }
        });
    })

    document.body.addEventListener('click', function (event) {
        var calculatorType = ''
        // console.log(`the target is: ${typeof event.target}`)
        if (event.target.classList.contains('calc-print-button')) {

            document.querySelector('.result-tab-container.deposit').classList.remove('selected-for-print');
            document.querySelector('.result-tab-container.borrow').classList.remove('selected-for-print');
            document.querySelector('.result-tab-container.repayments').classList.remove('selected-for-print');

            if (event.target.classList.contains('deposit')) {
                printContainer = document.getElementById('deposit_print_container');
                document.querySelector('.result-tab-container.deposit').classList.add('selected-for-print');;
            }
            if (event.target.classList.contains('borrow')) {
                printContainer = document.getElementById('borrow_print_container');
                document.querySelector('.result-tab-container.borrow').classList.add('selected-for-print');;
            }
            if (event.target.classList.contains('repayments')) {
                printContainer = document.getElementById('repayments_print_container');
                document.querySelector('.result-tab-container.repayments').classList.add('selected-for-print');;
            }

            for (let item of printContainers) {
                item.classList.remove('selected-for-print');
            }

            // make visible on print
            if (printContainer !== null) {
                printContainer.classList.add('selected-for-print');
            }

            //generateVirtualPageView('print');
            //updatePrintContainers(true);
            window.print();
        }
        //    else if (event.target.classList.contains('input-plus-minus')) {
        //        var inputId = '';
        //        var increment = true;
        //        if (event.target.classList.contains('svg-minus')) {
        //            inputId = event.target.getAttribute("for")
        //            increment = false;
        //        } else if (event.target.classList.contains('svg-plus')) {
        //            inputId = event.target.getAttribute("for")
        //            increment = true;
        //        }
        //        if (inputId !== '') {
        //            updateUpDownInput(inputId, increment)
        //        }
        //    }
    });

    function updateUpDownInput(elementId, increment) {
        var element = document.getElementById(elementId);
        if (element !== null) {
            var stepValue = parseInt(element.getAttribute('step'));
            var minValue = parseInt(element.getAttribute('min'));
            var maxValue = parseInt(element.getAttribute('max'));
            var valValue = parseInt(element.value);

            minValue = !isNaN(minValue) ? minValue : 0;
            stepValue = !isNaN(stepValue) ? stepValue : 1;
            valValue = !isNaN(valValue) ? valValue : minValue;
            maxValue = !isNaN(maxValue) ? maxValue : 100;

            valValue = increment ? valValue + stepValue : valValue - stepValue;
            valValue = valValue < minValue ? minValue : valValue;
            valValue = valValue > maxValue ? maxValue : valValue;

            if (elementId == "borrow_number_of_applicants") {
                for (var i = valValue; i <= 4; i++) {
                    //document.querySelector(`div.text-input.borrow_borrower_${i}_income`).style.display = "none";
                    document.querySelector(`div.text-input-borrow.borrow_borrower_${i}_income`).classList.remove('active')
                }
                for (var i = 1; i <= valValue; i++) {
                    //document.querySelector(`div.text-input.borrow_borrower_${i}_income`).style.display = "flex";
                    document.querySelector(`div.text-input-borrow.borrow_borrower_${i}_income`).classList.add('active')
                }
            }
            element.value = `${valValue}`;
        }
    }

    /** function fillPrintDetails
 | Populates the print container with the users calculatioln results
 | in  preperation for viewing in Print Mode
 */
    window.fillPrintDetails = (type) => {

        if (type === 'deposit') {
            let isFirstHomeBuyer = document.getElementById('deposit_first_home_buyer_yes') !== null ? document.getElementById('deposit_first_home_buyer_yes').checked ? 'Yes' : 'No' : '-';
            let purchasePrice = document.getElementById('deposit_property_price') !== null ? document.getElementById('deposit_property_price').value : '-';
            let purpose = document.getElementById('deposit_build_or_buy_buy') !== null ? document.getElementById('deposit_build_or_buy_buy').checked ? 'Buy' : 'Build' : '-';
            let location = document.getElementById('deposit_suburb') !== null ? document.getElementById('deposit_suburb').value : '-';

            document.querySelector("[data-deposit-printfill=IsFirstHomeBuyer]").innerHTML = isFirstHomeBuyer;
            document.querySelector("[data-deposit-printfill=PurchasePrice]").innerHTML = purchasePrice;
            document.querySelector("[data-deposit-printfill=Purpose]").innerHTML = purpose;
            document.querySelector("[data-deposit-printfill=Locality]").innerHTML = location;
        }
    }

    window.openTab = (event, tabId, tabType ) => {
        // Remove is-active class from all tab headers
        let tabHeaders = document.querySelectorAll(`[data-type="${tabType}"]`);

        for (let i = 0; i < tabHeaders.length; i++) {
            tabHeaders[i].classList.remove('is-active');
        }

        // Remove is-active class from all tab contents
        let tabContents = document.querySelectorAll(`[id^="${tabType}_"].tab-content`);
        for (let i = 0; i < tabContents.length; i++) {
            tabContents[i].classList.remove('is-active');
        }

        // Add is-active class to the clicked tab header
        event.currentTarget.classList.add('is-active');

        // Add is-active class to the corresponding tab content
        document.getElementById(tabId).classList.add('is-active');
    }

    window.toggleBreakdown = (event, selector, subSelector) => {
        let css = `.${selector}.breakdown.${subSelector}`
        let breakdown = document.querySelector(css);
        breakdown.classList.toggle("breakdown-hidden")
        let toggleText = breakdown.querySelector('div>.toggle-text');
        if (breakdown.classList.contains("breakdown-hidden") && toggleText) {
            toggleText.innerHTML = 'View breakdown';
        } else {
            toggleText.innerHTML = 'Hide breakdown';
        }
    }


}

