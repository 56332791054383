﻿export default function initKeyFacts(window) {

    window.hasKeyFactsEventListener = 0
    window.initialized = false
    function toggleSpinner(isVisible) {
        var btnSpinner = document.querySelector('div[id^="form-KeyFactsSheet"]').querySelector('button .spinner');
        var btnChevron = document.querySelector('div[id^="form-KeyFactsSheet"]').querySelector('button .decoration');
        if (isVisible) {
            btnSpinner.style.display = 'block'
            btnChevron.style.display = 'none'
        } else {
            btnSpinner.style.display = 'none'
            btnChevron.style.display = 'block'
        }
    }

    function addDownloadButton(pdfUrl) {
        var formWrapper = document.querySelector('div[id^="form-KeyFactsSheet"]');
        var form = formWrapper.querySelector('form');
        const nextFormWrapperId = form.getAttribute('data-ktc-ajax-update')

        if (!form) {
            console.log('KFS: failed to find updated form to insert error')
            console.error(formWrapperId);
        }

        var info = document.createElement("div");
            info.classList.add('kfs-form-download')
            info.innerHTML = '<br/><p><a href="' + pdfUrl + '" class="button hollow atlantic-blue" target="_blank" download><span class="label">Download Results<span></a></p>';
            form.append(info);
    }

    function getPdfLink(loanAmount, interestType) {
        console.log('Parameters: ', loanAmount, interestType);

        var form = document.querySelector('div[id^="form-KeyFactsSheet"]').querySelector('form');
        var btnSpinner = document.querySelector('div[id^="form-KeyFactsSheet"]').querySelector('button .spinner');
        var btnChevron = document.querySelector('div[id^="form-KeyFactsSheet"]').querySelector('button .decoration');
        toggleSpinner(true)
        var url = `${window.location.origin}/KeyFacts/GenerateKFSPDF?loanAmount=${loanAmount}&interestType=${interestType}`;

        const xhr = new XMLHttpRequest();

        // Set the URL and HTTP method for the request
        xhr.open("GET", url);

        // Define a callback function to handle the response
        xhr.onload = function () {
            if (xhr.status === 200) {
                // Request was successful and return the callback function
                //console.log(xhr.responseText);
                toggleSpinner(false)
                if (window.canRenderPDF) {
                    window.open(xhr.responseText);
                } else {
                    addDownloadButton(xhr.responseText);
                }
            } else {
                // Request failed
                displayError()
                toggleSpinner(false)
            }
        };

        // Define a callback function to handle any errors
        xhr.onerror = function () {
            displayError()
            toggleSpinner(false)
        };

        // Send the request to the server
        xhr.send();

    }
    function displayError() {
        var formWrapper = document.querySelector('div[id^="form-KeyFactsSheet"]');
        var form = formWrapper.querySelector('form');

        if (!form) {
            console.log('KFS: failed to find updated form to insert error')
        }
        if (form.querySelector('.key-facts-error')) {
            form.querySelector('.key-facts-error').remove()
        }
        let div = document.createElement("div");
        div.classList.add('key-facts-error')
        div.style.display = "block";
        div.style.marginTop = "20px";
        div.innerHTML = `There was a problem retrieving your file. Please try again later or <a href="/contact-us">contact us for assistance</a>.`
        form.append(div)
    }

    function handlekeyFactsForm(e) {
        if (!window.initialized) {
            window.maxLoan = parseFloat(document.getElementById('KFSdata').getAttribute('data-property-kfsmaxloan'));
            window.minLoan = parseFloat(document.getElementById('KFSdata').getAttribute('data-property-kfsminloan'));
            window.maxloanError = document.getElementById('KFSdata').getAttribute('data-property-kfsmaxloanmessage');
            window.minloanError = document.getElementById('KFSdata').getAttribute('data-property-kfsminloanmessage');
            window.initialized = true;
        }
        var formWrapper = document.querySelector('div[id^="form-KeyFactsSheet"]');
        let form = document.querySelector('div[id^="form-KeyFactsSheet"]').querySelector('form');
        const loanAmount = form.querySelector('input[name*=LoanAmount]').value.replace(/,/g, '');
        const interestType = form.querySelector('select[name*=InterestType]').value;

        var previousError = document.querySelector('.kfs-form-validation-error');

        if (loanAmount > window.maxLoan) {

            e.preventDefault();
            e.stopPropagation();
            if (!previousError) {
                var error = document.createElement("span");
                error.innerHTML = window.maxloanError;
                error.classList.add("error");
                error.classList.add("kfs-form-validation-error");
                //error.style.marginTop = 10;
                formWrapper.append(error);
            } else {
                previousError.innerHTML = window.maxloanError;
            }
            setTimeout(applyNewBinding, 5000);
            return;
        }

        if (loanAmount < window.minLoan) {
            e.preventDefault();
            e.stopPropagation();
            if (!previousError) {
                var error = document.createElement("span");
                error.innerHTML = window.minloanError;
                error.classList.add("error");
                error.classList.add("kfs-form-validation-error");
                //error.style.marginTop = 10;
                formWrapper.append(error);
            } else {
                previousError.innerHTML = window.minloanError;
            }
            setTimeout(applyNewBinding, 5000);
            return;
        }

        // Check if form is valid
        if (form.querySelectorAll('.field-validation-error').length) {
            setTimeout(applyNewBinding, 5000);
            return;
        }

        if (previousError) {
            previousError.remove();
        }

        // The id attribute chanses after every submission so we need to grab it from the response
        const nextFormWrapperId = form.getAttribute('data-ktc-ajax-update')
        //document.getElementById(nextFormWrapperId).style.border('3px red');

        getPdfLink(loanAmount, interestType);
    }

    function applyNewBinding() {
        //console.log('KFS: Applying new binding');
        var formWrapper = document.querySelector('div[id^="form-KeyFactsSheet"]');
        if (formWrapper) {
            var form = formWrapper.querySelector('form');

            if (form) {
                var submitbutton = form.querySelector('button[type="submit"]');
                if (window.hasKeyFactsEventListener == 1) {
                    submitbutton.removeEventListener('click', handlekeyFactsForm);
                }

                //console.log('attaching eventListener')
                submitbutton.addEventListener('click', handlekeyFactsForm);
                //console.log('eventListener hopefully attached')
                window.hasKeyFactsEventListener = 1
            }

        }
    }

    applyNewBinding();

}

