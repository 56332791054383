﻿import {
    maybeParseInt,
    maybeParseFloat,
    calculatorLoadStart
} from "./calculator-utils";
import calculatorDisplayBorrowResults from "./calculator-handle-borrow-results";
import { getPostCode } from "./calculator-suburb-list";

function calculatorLoadError(text = false) {
    if (text != false) {
        //console.log('custom error text', text);
        document.querySelector('.borrow-error > p').innerHTML = text;
    } else {
        document.querySelector('.borrow-error > p').innerHTML = "The system is unable to load the results at the moment due to an internet connection issue. Please tty again."
    }
    document.querySelector('.borrow-error').style.display = 'block';
    document.getElementById('borrow_calculate').classList.remove('loading');
}

export default function () {

    let borrowCalculate = document.getElementById("borrow_calculate");
    let borrowReset = document.getElementById("borrow_reset");
    if (borrowCalculate) {
        borrowCalculate.addEventListener("click", function (e) {
            e.preventDefault();
            document.querySelector('.borrow-error').style.display = 'none';
            document.getElementById('borrow_calculate').classList.add('loading');

            var price = parseFloat(document.getElementById("borrow_property_price").value.replace(/,/g, ''));
            var maxPrice = parseFloat(document.getElementById('validation-data').getAttribute('data-property-max').replace(/,/g, ''));
            var minPrice = parseFloat(document.getElementById('validation-data').getAttribute('data-property-min').replace(/,/g, ''));
            document.getElementById("borrow_property_price").classList.remove("is-invalid-input");

            if (price < minPrice || price > maxPrice) {
                console.log("Validation failed, price: " + price + ", too low: " + (price < minPrice) + ", too high: " + (price > maxPrice) + ", maxPrice: " + maxPrice);
                console.log("javascript says " + price + " > " + maxPrice);
                var priceElem = document.getElementById('borrow_property_price');
                priceElem.classList.add("is-invalid-input");

                var errorText = "Enter a price between " + minPrice + " and " + maxPrice;
                calculatorLoadError(errorText)
                return;
            }
            let income1 = maybeParseFloat(document.getElementById('borrow_borrower_1_income').value);
            if (income1 < 50) {
                var errorText = "Please enter a value of at least $50 for the income of Borrower 1.";
                calculatorLoadError(errorText)
                return;
            }

            window.results = new Array();

            let suburbInput = document.getElementById('borrow_suburb').value;
            let postCode = getPostCode(suburbInput);
            if (document.getElementById('borrow_suburb').value == '') {
                document.getElementById('borrow_suburb').value = 'ADELAIDE'
            }

            var numBorrowers = document.querySelectorAll('.borrower-income.active').length;
            let amountArray = [];
            let frequencyArray = [];

            for (let i = 0; i < numBorrowers; i++) {
                let amount = maybeParseFloat(document.getElementById('borrow_borrower_' + (i + 1) + '_income').value);
                amountArray.push(amount);
                frequencyArray.push(document.getElementById('borrow_borrower_' + (i + 1) + '_income_frequency').value);
            }
            var commitmentSelect = document.getElementById('borrow_other_commitments_frequency');

            let data = {
                Amount: amountArray,
                Frequency: frequencyArray,
                CommitmentAmount: maybeParseFloat(document.getElementById('borrow_other_commitments').value),
                CommitmentFrequency: commitmentSelect.options[commitmentSelect.selectedIndex].text,
                GivenPurchasePrice: price,
                IsFirstHomeBuyer: document.getElementById("borrow_first_home_buyer_yes").checked ? "true" : "false",
                LoanAmount: maybeParseFloat(document.getElementById("borrow_property_price").value),
                NumberOfApplicants: document.querySelectorAll('.borrower-income.active').length,
                NumberOfDependents: maybeParseInt(document.getElementById("borrow_number_of_dependants").value),
                Postcode: postCode,
                Purpose: document.getElementById("borrow_build_or_buy_build").checked ? "BuyLandAndBuild" : "BuyHome",
                TotalCreditCardLimits: maybeParseInt(document.getElementById("borrow_total_credit_limit").value)
            };


            let url = window.location.origin + '/api/calculator/HMCIB';
            // console.log("posting to " + url, data);

            fetch(url, {
                method: 'POST', // Use POST if you want to send data in the request body
                headers: {
                    'Content-Type': 'application/json' // Send as JSON
                },
                body: JSON.stringify(data) // Convert data object to JSON string for sending
            }).then(response => {
                if (!response.ok || response.status == 'Fail') {
                    document.getElementById('borrow_calculate').classList.remove('loading');
                    document.querySelector('.borrow-error').style.display = 'flex';
                    //    throw new Error('Network response was not ok');
                } else {

                    response.json().then(result => {
                        console.log(result)
                        if (result.status == 'Fail') {
                            document.getElementById('borrow_calculate').classList.remove('loading');
                            document.querySelector('.borrow-error').style.display = 'flex';
                        } else {
                            calculatorDisplayBorrowResults(result);
                        }
                        //    console.log(result.scenarios);
                    });
                }
            });
        });
        borrowReset.addEventListener("click", function (e) {
            e.preventDefault();
            document.getElementById('borrow_results').style.display = 'none';
            document.getElementById('borrow_property_price').value = '';
            document.getElementById('borrow_build_or_buy_buy').checked = true;
            document.getElementById('borrow_first_home_buyer_yes').checked = true;
            document.getElementById('borrow_suburb').value = '';
        });

    }
}