﻿import {getFullSuburbList} from "./calculator-suburb-list";
import {getAutoCompletePostCodes}     from "./location-valuers-helpers";

//CORPORATE

export default function () {

    let elemList = document.querySelectorAll('input.suburb-selector');
    var suburbList = JSON.parse(localStorage.getItem("suburbs"));
    let suburbs = getFullSuburbList();
    let postCodeFinder = [];
    suburbs.forEach(function (obj) {
        postCodeFinder[obj['suburbName'].toLowerCase()] = obj["postCode"].toLowerCase();
    });

    if (suburbList == null || suburbList == "undefined") {

        var suburbList = getAutoCompletePostCodes();
        localStorage.setItem("suburbs", JSON.stringify(suburbList));
    }
    //applyAutocomplete()

    [...elemList].forEach( inp => { autocomplete(inp,suburbList) })

    function autocomplete(inp, arr) {
        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;
            /*close any already open lists of autocompleted values*/
            closeAllLists();
            if (!val) { return false; }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            var container = this.nextElementSibling
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            container.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length && val.length > 2; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i]['label'].toLowerCase() != arr[i]['value'].toLowerCase() &&(
                    arr[i]['label'].substr(0, val.length).toUpperCase() == val.toUpperCase()
                    || arr[i]['value'].substr(0, val.length).toUpperCase() == val.toUpperCase())) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    b.setAttribute("class", "autocomplete-item");
                    /*make the matching letters bold:*/
                    b.innerHTML = arr[i]['label'] + ' ' + arr[i]['value'];
                    /*insert a input field that will hold the current array item's value:*/
                    //b.innerHTML += "<input type='hidden' value='" + arr[i]['value'] + "'>";
                    b.innerHTML += `<input type='hidden' value='${arr[i]['value']}' data-zip='${arr[i]['label']}'>`;
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        var selectedItem = this.getElementsByTagName("input")[0];
                        //console.log('clicked')
                        /*insert the value for the autocomplete text field:*/
                        inp.value = selectedItem.value;
                        var zipElement = inp.parentElement.querySelector('input#ZipCode');
                        if (zipElement) {
                            zipElement.value = selectedItem.getAttribute('data-zip');
                        }
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists();
                    });
                    a.appendChild(b);
                }
            }
        });

        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            var x = document.getElementById(this.id + "autocomplete-list");
            if (x) x = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (x) x[currentFocus].click();
                }
            }
        });
        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }

    function getPostCodeForSuburb(suburb = "") {
        let result = undefined;
        suburbList.forEach(function (obj) {
            //console.log(obj);
            if (obj["value"].toLowerCase() == suburb.toLowerCase() && obj['label'].length == 4) {
                return obj["label"];
            }
        });
    }

}


