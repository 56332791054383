﻿
/**
 * Code sourced from https://downloads.reciteme.com/hubfs/ReciteStandardOnDemandImplementation.txt
 * then deobfuscated with https://www.dcode.fr/javascript-unobfuscator
 * then `loadService` was marked as export default
 */
var serviceUrl = "//api.reciteme.com/asset/js?key=";
var serviceKey = "fdfe031b96fc74ea5d6222161f51a0050925e37e";
var options = {};
var autoLoad = false;
var enableFragment = "#reciteEnable";
var loaded = [];
var frag = false;
if (window.location.hash === enableFragment) {
    frag = true;
}
function loadScript(url, ok) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    if (script.readyState) {
        script.onreadystatechange = function () {
            if ("loaded" == script.readyState || "complete" == script.readyState) {
                script.onreadystatechange = null;
                if (void 0 != ok) {
                    ok();
                }
            }
        };
    } else {
        if (void 0 != ok) {
            script.onload = function () {
                ok();
            };
        }
    }
    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
}
function _rc(delimiter) {
    delimiter += "=";
    var codeSegments = document.cookie.split(";");
    var i = 0;
    for (; i < codeSegments.length; i++) {
        var cookie = codeSegments[i];
        for (; " " == cookie.charAt(0);) {
            cookie = cookie.substring(1, cookie.length);
        }
        if (0 == cookie.indexOf(delimiter)) {
            return cookie.substring(delimiter.length, cookie.length);
        }
    }
    return null;
}
export default function loadService(fn) {
    var url = serviceUrl + serviceKey;
    var i = 0;
    for (; i < loaded.length; i++) {
        if (loaded[i] == url) {
            return;
        }
    }
    loaded.push(url);
    loadScript(serviceUrl + serviceKey, function () {
        if ("function" === typeof _reciteLoaded) {
            _reciteLoaded();
        }
        if ("function" == typeof fn) {
            fn();
        }
        Recite.load(options);
        Recite.Event.subscribe("Recite:load", function () {
            Recite.enable();
        });
    });
}
if ("true" == _rc("Recite.Persist")) {
    loadService();
}
if (autoLoad && "false" != _rc("Recite.Persist") || frag) {
    if (document.addEventListener) {
        document.addEventListener("DOMContentLoaded", function (dataAndEvents) {
            loadService();
        });
    } else {
        loadService();
    }
}
