﻿
function userMenu() {
    let userMenu = document.getElementById('user-menu');
    if (userMenu === null) {
        return;
    }
    let userMenuButton = userMenu.querySelector('button');
    let userMenuDropdown = userMenu.querySelector('.drop-down');
    let isOpen = false;
    function openDropdown() {
        userMenu.classList.add('expanded');
        userMenuButton.setAttribute('aria-expanded', 'true');
        userMenuDropdown.removeAttribute('hidden');
        userMenuDropdown.querySelector('a, button').focus();
        isOpen = true;
    }
    function closeDropdown() {
        userMenu.classList.remove('expanded');
        userMenuButton.setAttribute('aria-expanded', 'false');
        userMenuDropdown.setAttribute('hidden', 'hidden');
        isOpen = false;
    }
    function toggleDropdown() {
        if (isOpen) {
            closeDropdown();
        }
        else {
            openDropdown();
        }
    }
    userMenuButton.addEventListener('click', toggleDropdown);
    userMenu.addEventListener('focusout', function() {
        if (!userMenu.contains(event.relatedTarget)) {
            closeDropdown();
        }
    });
    let menuNotificationBadge = document.getElementById('menu-notification-badge');
    let notificationsMenuItemNotificationBadge = document.getElementById('notifications-menu-item-notification-badge');
    if (menuNotificationBadge !== null && notificationsMenuItemNotificationBadge !== null)
    {
        window.fetch('/api/calmsdashboard/GetNewNotificationCount')
        .then(function(result) { return result.json(); })
        .then(function(notificationCount) {
            if (notificationCount === 0) {
                return;
            }
            menuNotificationBadge.textContent = notificationCount.toString();
            notificationsMenuItemNotificationBadge.textContent = notificationCount.toString();
            menuNotificationBadge.removeAttribute('hidden', 'hidden');
            notificationsMenuItemNotificationBadge.removeAttribute('hidden', 'hidden');
        });
    }
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', userMenu);
}
else {
    userMenu();
}
