﻿import {
    formatCurrency,
    updateTabTitle,
    calculateDuration,
    generateVirtualPageView,
} from "./calculator-utils";

//export function updateCalculatorButtonText(pretext, amount, calcType) {
//    document.querySelector(`.action-button`).classList.remove('loading');
//    document.querySelector(".action-button").classList.remove('calculator-error');
//    document.querySelector(`.action-button`).classList.add('results');
//    document.querySelector(`.result-label.${calcType}`).innerHTML = pretext;
//    document.querySelector(`.result-amount.${calcType}`).innerHTML = amount;
//}

export default function (results, calcType = 'deposit') {
    document.querySelector('.result-tab-container.deposit').classList.remove('selected-for-print');
    document.querySelector('.result-tab-container.borrow').classList.remove('selected-for-print');
    document.querySelector('.result-tab-container.repayments').classList.remove('selected-for-print');


    if (results.ErrorMessage != "" && results.ErrorMessage != null) {
        console.log('Encountered API error', results);
        calculatorLoadError(results.ErrorMessage);
    }
    if (Object.keys(results).length < 3) {
        console.log('Skipping rendering of results', results, Object.keys(results).length)
        return;
    }

    document.querySelector('.result-tab-container.deposit').classList.add('selected-for-print');;

    document.getElementById('deposit_calculate').classList.remove('loading');
    [...(document.getElementById('deposit_results_tabs').querySelectorAll('.tab-header'))].forEach((el) => { el.classList.remove('hide') })
    document.getElementById('deposit_graduate_loan').classList.remove('hide');
    document.getElementById('deposit_lowdeposit_loan').classList.remove('hide');
    let standardAdvantage = results.scenarios.find(function (value) { return value.scenarioName == "STANDARDADVANTAGE"; });
    let graduateAdvantage = results.scenarios.find(function (value) { return value.scenarioName == "GRADUATEADVANTAGE" && value.status != "Fail"; });
    let lowDepositAdvantage = results.scenarios.find(function (value) { return value.scenarioName == "LOWDEPOSITADVANTAGE" && value.status != "Fail"; });

    let lowestDeposit = standardAdvantage.totalDepositRequired;

    results.scenarios.forEach(function (item) {
        if (item.totalDepositRequired < lowestDeposit && item.status != "Fail") {
            lowestDeposit = item.totalDepositRequired;
        }
    });

    document.querySelector(`.result-amount.${calcType}`).innerHTML = formatCurrency(lowestDeposit, true);

    // STANDARD
    depositFillResultsTable("homestart", standardAdvantage);
    var amount = formatCurrency(standardAdvantage.totalDepositRequired, true);
    updateTabTitle("deposit_homestart", amount);

    //GRADUATE
    if (graduateAdvantage) {
        depositFillResultsTable("graduate", graduateAdvantage);
        var amount = formatCurrency(graduateAdvantage.totalDepositRequired, true);
        document.getElementById('deposit_graduate_loan_results').classList.remove('hide');
        document.getElementById('deposit_graduate_loan_option').classList.remove('hide');

        updateTabTitle("deposit_graduate", amount);
    } else {
        document.getElementById('deposit_graduate_loan_results').classList.add('hide');
        document.getElementById('deposit_graduate_loan_option').classList.add('hide');
        document.getElementById('deposit_graduate_loan').classList.add('hide');
    }

    // LOW DEPOSIT
    if (lowDepositAdvantage) {
        depositFillResultsTable("lowdeposit", lowDepositAdvantage);
        var amount = formatCurrency(lowDepositAdvantage.totalDepositRequired, true);
        document.getElementById('deposit_lowdeposit_loan_results').classList.remove('hide');
        document.getElementById('deposit_lowdeposit_loan_option').classList.remove('hide');
        updateTabTitle("deposit_lowdeposit", amount);
    } else {
        // HOMESTART-BL: When build is selected Low Deposit loans are not valid (even though the API returns valid results)
        document.getElementById('deposit_lowdeposit_loan_results').classList.add('hide');
        document.getElementById('deposit_lowdeposit_loan_option').classList.add('hide');
        document.getElementById('deposit_lowdeposit_loan').classList.add('hide');

    }
    updatePrintView();
    document.getElementById('deposit_results').style.display = 'block'
}

function updatePrintView() {
    document.querySelector('[data-deposit-printfill="IsFirstHomeBuyer"]').innerHTML = document.getElementById("deposit_first_home_buyer_yes").checked ? "Yes" : "No"
    document.querySelector('[data-deposit-printfill="PurchasePrice"]').innerHTML = `$ ${document.getElementById('deposit_property_price').value}`
    document.querySelector('[data-deposit-printfill="Purpose"]').innerHTML = document.getElementById("deposit_build_or_buy_build").checked ? "Build" : "Buy"
    document.querySelector('[data-deposit-printfill="Locality"]').innerHTML = document.getElementById('deposit_suburb').value
}


function depositFillResultsTable(selector, scenario) {


    // selector -> homestart | graduate | lowdeposit
    let tabheader = document.getElementById(`deposit_${selector}_loan_results`);
    let tabcontent = document.getElementById(`deposit_${selector}_loan`);
    // console.log('DEPOSIT RESULT');


    if (!scenario || scenario.status == "Fail") {
        console.log('scenario is not valid');
        tabheader.style.visibility = 'hide';
        tabcontent.style.visibility = 'hide';
    } else {

        tabheader.style.visibility = 'visible';
        tabcontent.style.visibility = 'visible';
        calculateDuration(selector, scenario);

        let borrow = document.getElementById(`deposit_${selector}_borrow_text`);
        let repayments = document.getElementById(`deposit_${selector}_repayments_text`);
        let contribution = document.getElementById(`deposit_${selector}_contribution_text`);

        borrow.innerHTML = formatCurrency(scenario.homeDeposit, true);
        repayments.innerHTML = formatCurrency(scenario.totalFeesAndCharges, true);
        contribution.innerHTML = formatCurrency(scenario.totalDepositRequired, true);

        var feesTable = document.getElementById(`deposit_breakdown_table_${selector}`);
        feesTable.innerHTML = '';
        // Home Deposit Row
        let row = document.createElement('tr');
        row.classList.add("bold")

        let name = document.createElement('td');
        name.innerText = 'Home Deposit';
        row.append(name);

        let amount = document.createElement('td');
        amount.classList.add("fees");
        amount.classList.add("deposit");
        amount.innerText = formatCurrency(scenario.homeDeposit, true);
        row.append(amount);
        feesTable.append(row);

        // Fees & Charges ROW
        row = document.createElement('tr');
        row.classList.add("bold")

        name = document.createElement('td');
        name.innerText = 'Fees & Charges';
        row.append(name);

        amount = document.createElement('td');
        amount.classList.add("fees");
        amount.classList.add("subtotal");
        amount.innerText = formatCurrency(scenario.totalFeesAndCharges, true);
        row.append(amount);
        feesTable.append(row);

        // Other fees & charges
        if (scenario.feesAndChargesBreakdown) {
            for (let i = 0; i < scenario.feesAndChargesBreakdown.length; i++) {
                row = document.createElement('tr');
                row.classList.add("sub");
                name = document.createElement('td');
                var span = document.createElement('span');
                span.innerText = scenario.feesAndChargesBreakdown[i].name;
                name.append(span);
                row.append(name);
                amount = document.createElement('td');
                amount.innerText = formatCurrency(scenario.feesAndChargesBreakdown[i].value);
                row.append(amount);
                feesTable.append(row);
            }
        }

        //Grants
        if (scenario.totalGrants > 0) {
            row = document.createElement('tr');
            row.classList.add("total")

            name = document.createElement('td');
            name.innerText = 'Government Grants';
            row.append(name);

            amount = document.createElement('td');
            amount.classList.add("fees");
            amount.classList.add("grandtotal");
            amount.innerText = "-" + formatCurrency(scenario.totalGrants, true);
            row.append(amount);

            feesTable.append(row);

            for (let i = 0; i < scenario.grants.length; i++) {
                row = document.createElement('tr');
                row.classList.add("sub");
                name = document.createElement('td');
                name.innerText = scenario.grants[i].name;
                row.append(name);
                amount = document.createElement('td');
                amount.innerText = "-" + formatCurrency(scenario.grants[i].value);
                row.append(amount);
                feesTable.append(row);
            }
        }

        // Totals
        row = document.createElement('tr');
        row.classList.add("total","bold")

        name = document.createElement('td');
        name.innerText = 'Total contribution required';
        row.append(name);

        amount = document.createElement('td');
        amount.classList.add("fees");
        amount.classList.add("grandtotal");
        amount.innerText = formatCurrency(scenario.totalDepositRequired, true);
        row.append(amount);

        feesTable.append(row);
    }
};
