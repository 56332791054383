﻿import {
    maybeParseInt,
    maybeParseFloat,
    formatCurrency,
    updateTabTitle,
    calculateDuration,
} from "./calculator-utils";

export default function (results) {
    let calcType = 'borrow'

    document.querySelector('.result-tab-container.deposit').classList.remove('selected-for-print');
    document.querySelector('.result-tab-container.borrow').classList.remove('selected-for-print');
    document.querySelector('.result-tab-container.repayments').classList.remove('selected-for-print');


    document.getElementById('borrow_calculate').classList.remove('loading');

    // If the API returns an error message exit early and display that.
    if (results.errors.length > 0) {
        console.log('Encountered API error', results);
        //calculatorLoadError(results.errors[0].description);
        return;
    }
    document.querySelector('.result-tab-container.borrow').classList.add('selected-for-print');

    // Calculate highest total amount to display in calculator button
    let highestTotalAmount = 0;

    results.scenarios.forEach(function (item) {
        //console.log(item.scenarioName + ": " + item.maximumBorrowableAmount);
        if (item.maximumBorrowableAmount > highestTotalAmount) {
            highestTotalAmount = item.maximumBorrowableAmount;
        }
    });

    document.querySelector(`.result-amount.${calcType}`).innerHTML = formatCurrency(highestTotalAmount, true);
    document.querySelector(`.action-button`).classList.remove('loading');

    let StandardAdvantage = results.scenarios.find(function (value) { return value.scenarioName.toUpperCase() == "STANDARDADVANTAGE"; });
    let StandardSharedEquity = results.scenarios.find(function (value) { return value.scenarioName.toUpperCase() == "STANDARDSHAREDEQUITY"; });
    let GraduateAdvantage = results.scenarios.find(function (value) { return value.scenarioName.toUpperCase() == "GRADUATEADVANTAGE"; });
    let GraduateSharedEquity = results.scenarios.find(function (value) { return value.scenarioName.toUpperCase() == "GRADUATESHAREDEQUITY"; });
    let LowDepositAdvantage = results.scenarios.find(function (value) { return value.scenarioName.toUpperCase() == "LOWDEPOSITADVANTAGE"; });
    let LowDepositSharedEquity = results.scenarios.find(function (value) { return value.scenarioName.toUpperCase() == "LOWDEPOSITSHAREDEQUITY"; });


    borrowerFillResultsTable("homestart", StandardAdvantage, 'standard');
    borrowerFillResultsTable("homestart", StandardSharedEquity, 'equity');


    if ((GraduateAdvantage.status == "Fail" || GraduateAdvantage == null) && (GraduateSharedEquity.status == "Fail" || GraduateSharedEquity == null)) {
        document.getElementById("borrow_graduate_loan_results").classList.add('hide');
        document.getElementById("borrow_graduate_loan_option").classList.add('hide');
        document.getElementById("borrow_graduate_loan").classList.add('hide');
    } else {
        document.getElementById("borrow_graduate_loan_results").classList.remove('hide');
        document.getElementById("borrow_graduate_loan_option").classList.remove('hide');
        document.getElementById("borrow_graduate_loan").classList.remove('hide');
        borrowerFillResultsTable("graduate", GraduateAdvantage, 'standard');
        borrowerFillResultsTable("graduate", GraduateSharedEquity, 'equity');
    }

    if ((LowDepositAdvantage.status == "Fail" || LowDepositAdvantage == null) && (LowDepositSharedEquity.status == "Fail" || LowDepositSharedEquity == null)) {
        document.getElementById("borrow_lowdeposit_loan_results").classList.add('hide');
        document.getElementById("borrow_lowdeposit_loan_option").classList.add('hide');
        document.getElementById('borrow_lowdeposit_loan').classList.add('hide');

    } else {
        document.getElementById("borrow_lowdeposit_loan_results").classList.remove('hide');
        document.getElementById("borrow_lowdeposit_loan_option").classList.remove('hide');
        document.getElementById('borrow_lowdeposit_loan').classList.remove('hide');

        borrowerFillResultsTable("lowdeposit", LowDepositAdvantage, 'standard');
        borrowerFillResultsTable("lowdeposit", LowDepositSharedEquity, 'equity');
    }
    var amount = formatCurrency(Math.max(StandardAdvantage.maximumBorrowableAmount, StandardSharedEquity.maximumBorrowableAmount), true);
    updateTabTitle("borrow_homestart", amount);
    amount = formatCurrency(Math.max(GraduateAdvantage.maximumBorrowableAmount, GraduateSharedEquity.maximumBorrowableAmount), true);
    updateTabTitle("borrow_graduate", amount);
    amount = formatCurrency(Math.max(LowDepositAdvantage.maximumBorrowableAmount, LowDepositSharedEquity.maximumBorrowableAmount), true);
    updateTabTitle("borrow_lowdeposit", amount);
    updatePrintView();
    document.getElementById('borrow_results').style.display = 'block'

};

function updatePrintView() {
    var numBorrowers = document.querySelectorAll('.borrower-income.active').length;
    var commitmentSelect = document.getElementById('borrow_other_commitments_frequency');
    var commitmentValue = maybeParseFloat(document.getElementById('borrow_other_commitments').value);
    var commitmentFrequency = '';
    if (commitmentValue > 0) {
        commitmentFrequency = ` / ${commitmentSelect.options[commitmentSelect.selectedIndex].text}`
    }

    const printContainers = [].slice.call(document.querySelectorAll('.print-container'));

    for (let item of printContainers) {
        item.classList.remove('selected-for-print');
    }

    // make visible on print
    document.getElementById('borrow_print_container').classList.add('selected-for-print');


    document.querySelector('[data-borrow-printfill="IsFirstHomeBuyer"]').innerHTML = document.getElementById("borrow_first_home_buyer_yes").checked ? "Yes" : "No"
    document.querySelector('[data-borrow-printfill="PurchasePrice"]').innerHTML = `$${document.getElementById('borrow_property_price').value}`
    document.querySelector('[data-borrow-printfill="Purpose"]').innerHTML = document.getElementById("borrow_build_or_buy_build").checked ? "Build" : "Buy"
    document.querySelector('[data-borrow-printfill="Locality"]').innerHTML = document.getElementById('borrow_suburb').value
    document.querySelector('[data-borrow-printfill="OtherMonthlyCommitments"]').innerHTML = `$${maybeParseFloat(document.getElementById('borrow_other_commitments').value)}${commitmentFrequency}`
    document.querySelector('[data-borrow-printfill="NumberOfBorrowers"]').innerHTML = `${numBorrowers}`;
    document.querySelector('[data-borrow-printfill="NumberOfDependants"]').innerHTML = maybeParseInt(document.getElementById("borrow_number_of_dependants").value);
    document.querySelector('[data-borrow-printfill="StoreCreditCardLimits"]').innerHTML = `$${maybeParseFloat(document.getElementById("borrow_total_credit_limit").value)}`;
    //document.querySelector('[data-borrow-printfill="LoanAmount"]').innerHTML = `$${maybeParseFloat(document.getElementById("borrow_property_price").value)}`;

    for (let i = 1; i <= 4; i++) {
        document.querySelector(`tr.Borrower${i}Earnings`).classList.remove('print-hide')
    }

    for (let i = 0; i < numBorrowers; i++) {
        let income = `$ ${document.getElementById('borrow_borrower_' + (i + 1) + '_income').value} / ${document.getElementById('borrow_borrower_' + (i + 1) + '_income_frequency').options[commitmentSelect.selectedIndex].text}`;
        document.querySelector(`[data-borrow-printfill="Borrower${(i + 1)}Earnings"]`).innerHTML = income;
    }
    for (let i = numBorrowers + 1; i <= 4; i++) {
        document.querySelector(`tr.Borrower${i}Earnings`).classList.add('print-hide')
    }
}

function borrowerFillResultsTable(selector, scenario, advantageType = "") {
    let tabheader = document.getElementById(`borrow_${selector}_loan_results`);
    let tabcontent = document.getElementById(`borrow_${selector}_loan`);
    console.log(`advantage type: ${advantageType}`)
    var equityType = advantageType == 'equity' ? '_equity' : '';


    if (!scenario || scenario.status == "Fail") {
        console.log('scenario is not valid');
        tabheader.style.visibility = 'hide';
        tabcontent.style.visibility = 'hide';
        document.querySelector('.result-tab-container.borrow').classList.remove('selected-for-print');
    } else {
        tabheader.style.visibility = 'visible';
        tabcontent.style.visibility = 'visible';
        //calculateDuration(selector, scenario, 'borrow');

        let borrow = document.getElementById(`borrow_${selector}_borrow_text${equityType}`);
        let repayments = document.getElementById(`borrow_${selector}_repayments_text${equityType}`);
        let contribution = document.getElementById(`borrow_${selector}_contribution_text${equityType}`);

        //console.log(`borrow = borrow_${selector}_borrow_text${equityType}`)
        //console.log(`repayments = borrow_${selector}_repayments_text${equityType}`)
        //console.log(`contribution = borrow_${selector}_contribution_text${equityType}`)
        borrow.innerHTML = formatCurrency(scenario.maximumBorrowableAmount, true);
        repayments.innerHTML = formatCurrency(scenario.repaymentsAmount, true);
        contribution.innerHTML = formatCurrency(scenario.totalDepositRequired, true);

        var months = scenario.estimatedTerms;
        var years = Math.trunc(months / 12);
        var propertyVal = formatCurrency(scenario.purchasePrice,true);
        var element = document.getElementById(`borrow_${selector}_content_text${equityType}`);
        console.log(`selector = borrow_${selector}_content_text${equityType} - ${propertyVal}`)
        element.innerHTML = `Based on property value of ${propertyVal} and an estimated loan term of ${Math.trunc(years)} years and ${years % 12} months`;


        if (advantageType.length > 0) {
            if (scenario.secondaryLoanAmount - 0 > 0) {
                const headers = [].slice.call(document.querySelectorAll(`.advantage_text.${advantageType}`));
                for (let item of headers) {
                    item.style.visibility = 'visible';
                }
            } else {
                const headers = [].slice.call(document.querySelectorAll(`.advantage_text.${advantageType}`));
                for (let item of headers) {
                    item.style.visibility = 'hidden';
                }
            }
        }

        //calculateDuration(selector, scenario, 'borrow');

        var feesTable = document.getElementById(`borrow_breakdown_table_${selector}${equityType}`);
        feesTable.innerHTML = '';

        // Home Deposit Row
        let row = document.createElement('tr');
        row.classList.add("bold")

        let name = document.createElement('td');
        name.innerText = 'Home Deposit';
        row.append(name);

        let amount = document.createElement('td');
        amount.classList.add("fees");
        amount.classList.add("deposit");
        amount.innerText = formatCurrency(scenario.homeDeposit, true);
        row.append(amount);
        feesTable.append(row);

        // Fees & Charges ROW
        row = document.createElement('tr');
        row.classList.add("bold");

        name = document.createElement('td');
        name.innerText = 'Fees & Charges';
        row.append(name);

        amount = document.createElement('td');
        amount.classList.add("fees");
        amount.classList.add("subtotal");
        amount.innerText = formatCurrency(scenario.totalFeesAndCharges, true);
        row.append(amount);
        feesTable.append(row);

        if (scenario.feesAndChargesBreakdown) {
            for (let i = 0; i < scenario.feesAndChargesBreakdown.length; i++) {
                row = document.createElement('tr');
                row.classList.add("sub")
                name = document.createElement('td');
                name.innerText = scenario.feesAndChargesBreakdown[i].name;
                row.append(name);
                amount = document.createElement('td');
                amount.innerText = formatCurrency(scenario.feesAndChargesBreakdown[i].value);
                row.append(amount);
                feesTable.append(row);
            }
        }

        //Grants
        if (scenario.totalGrants > 0) {
            row = document.createElement('tr');
            row.classList.add("total")
            row.classList.add("bold")

            name = document.createElement('td');
            name.innerText = 'Government Grants';
            row.append(name);

            amount = document.createElement('td');
            amount.classList.add("fees");
            amount.classList.add("grandtotal");
            amount.innerText = "-" + formatCurrency(scenario.totalGrants, true);
            row.append(amount);

            feesTable.append(row);


            for (let i = 0; i < scenario.grants.length; i++) {
                row = document.createElement('tr');
                row.classList.add("sub");
                name = document.createElement('td');
                name.innerText = scenario.grants[i].name;
                row.append(name);
                amount = document.createElement('td');
                amount.innerText = "-" + formatCurrency(scenario.grants[i].value);
                row.append(amount);
                feesTable.append(row);
            }
        }

        // Totals
        row = document.createElement('tr');
        row.classList.add("total")

        name = document.createElement('td');
        name.innerText = 'Total contribution required';
        row.append(name);

        amount = document.createElement('td');
        amount.classList.add("fees");
        amount.classList.add("grandtotal");
        amount.innerText = formatCurrency(scenario.totalDepositRequired, true);
        row.append(amount);

        feesTable.append(row);
    }
};
